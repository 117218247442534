<template>
    <div>
        <el-form :inline="true">
            <!-- <el-form-item label="订单号" label-width="100">
                <el-input v-model="searchForm.billCode" placeholder="订单号" clearable>
                </el-input>
            </el-form-item> -->
            <el-form-item label="投诉人昵称" label-width="100">
                <el-input v-model="searchForm.userNiceName" placeholder="投诉人昵称" clearable>
                </el-input>
            </el-form-item>
            <el-form-item label="投诉人手机" label-width="100">
                <el-input v-model="searchForm.userPhone" placeholder="投诉人手机" clearable>
                </el-input>
            </el-form-item>
            <el-form-item label="被投诉人昵称" label-width="100">
                <el-input v-model="searchForm.complaintNiceName" placeholder="被投诉人昵称" clearable>
                </el-input>
            </el-form-item>
            <el-form-item label="被投诉人手机" label-width="100">
                <el-input v-model="searchForm.complaintPhone" placeholder="被投诉人手机" clearable>
                </el-input>
            </el-form-item>
            <el-form-item label="投诉状态" label-width="100">
                <el-select v-model="searchForm.complaintState" clearable placeholder="选择投诉状态">
                    <el-option label="待处理" :value="1"></el-option>
                    <el-option label="已处理" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="投诉人身份" label-width="100">
                <el-select v-model="searchForm.complaintTypeUse" clearable placeholder="选择投诉身份">
                    <el-option label="货主" :value="2"></el-option>
                    <el-option label="司机" :value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间：" label-width="100">
                <el-date-picker v-model="searchForm.createTime" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy年MM月dd日" value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button @click="getsearchF" type="success">搜索</el-button>
            </el-form-item>
        </el-form>

        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>
            <el-table-column label="订单id" align="center" prop="billId" width="80">
            </el-table-column>

            <el-table-column label="投诉人昵称" align="center" prop="userNiceName" width="120">
            </el-table-column>
            <el-table-column label="投诉人手机号" align="center" prop="userPhone" width="160">
            </el-table-column>

            <el-table-column label="被投诉人昵称" align="center" prop="complaintNiceName" width="120">
            </el-table-column>
            <el-table-column label="被投诉人手机号" align="center" prop="complaintPhone" width="160">
            </el-table-column>
            <el-table-column prop="complaintTypeUse" align="center" label="投诉人身份" width="100">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.complaintTypeUse === 2" type="info">货主</el-tag>
                    <el-tag size="small" v-else-if="scope.row.complaintTypeUse === 3" type="success">司机</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="投诉类别" align="center" prop="complaintCategory" min-width="160">
            </el-table-column>
            <el-table-column label="投诉说明" align="center" prop="complaint" min-width="160">
            </el-table-column>
            <el-table-column prop="createdTime" align="center" width="160" label="投诉时间"></el-table-column>
            <el-table-column prop="complaintState" align="center" label="处理状态" width="100">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.complaintState === 2" type="success">已处理</el-tag>
                    <el-tag size="small" v-else-if="scope.row.complaintState === 1" type="warning">未处理</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="processedTime" align="center" width="160" label="处理时间">
            </el-table-column>
            <el-table-column prop="icon" align="center" width="200" fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="sing(scope.row)"
                        v-if="scope.row.complaintState === 1">处理</el-button>
                        <el-button type="success" plain @click="detail(scope.row)">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20, 50, 100]" :current-page="current"
            :page-size="size" :total="total">
        </el-pagination>
        <handle ref="shared" v-if="detailvisible" @refreshDataList="getsearchF"></handle>
        <singDetail ref="detail" v-if="visible" />
    </div>
</template>

<script>
import ty from "@/utils/ty";
import handle from "./handle";
import singDetail from "./singDetail";
export default {
    name: "UserBase",
    components: {
        handle,
        singDetail
    },
    data() {
        return {
            visible: false,
            detailvisible: false,
            searchForm: {},
            tableData: [],
            total: 0,
            size: 10,
            current: 1,
        }
    },
    created() {
        this.getUserBase();
    },
    mounted() {
        // 判断浏览器是否是火狐
        if (navigator.userAgent.indexOf("Firefox") > 0) {
            this.isFirefox = true;
        }
    },
    methods: {
        detail(row) {
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.detail.init(row);
            })
        },
        sing(row) {
            this.detailvisible = true;
            this.$nextTick(() => {
                this.$refs.shared.init(row.complaintId);
            })
        },

        handleSizeChange(val) {
            this.size = val
            this.getUserBase()
        },
        handleCurrentChange(val) {
            this.current = val
            this.getUserBase()
        },
        getsearchF() {
            this.current = 1
            this.size = 10
            this.getUserBase()
        },

        getUserBase() {
            var startTime = '';
            var endTime = '';
            if (this.searchForm.createTime != undefined) {
                startTime = this.searchForm.createTime[0];
                endTime = this.searchForm.createTime[1];
            }
            this.searchForm.startTime = startTime
            this.searchForm.endTime = endTime;
            this.searchForm.pageNo = this.current
            this.searchForm.pageSize = this.size
            this.$axios.post("/admin/complaint/getAdminComplaintList", this.searchForm).then(res => {
                this.tableData = res.data.records
                this.total = res.data.total
            })
        },
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}
</style>
