<template>
    <!--新增对话框-->
    <el-dialog title="投诉处理" :visible.sync="dialogVisible" width="600px" :close-on-click-modal="false"
        :before-close="handleClose">
        <el-form :model="editForm" :rules="editFormRules" ref="editForm">
            <el-form-item label="处理说明：" prop="processingResults" label-width="150px">
                <el-input type="textarea" v-model="editForm.processingResults" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="评分：" prop="evaluationScore" label-width="150px">
                <el-rate v-model="editForm.evaluationScore" :colors="colors" :allow-half="true"  show-score score-template="{value}">
                </el-rate>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="resetForm('editForm')">取 消</el-button>
            <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
            editForm: {
                processingResults: null,
                complaintId: '',
                evaluationScore: 5
            },
            dialogVisible: false,
            editFormRules: {
                processingResults: [
                    { required: true, message: '请输入投诉原因', trigger: 'blur' }
                ],
            },
        }
    },

    methods: {
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogVisible = false
            this.editForm = {}
        },
        handleClose() {
            this.resetForm('editForm')
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(this.editForm)
                    this.$axios.post('/admin/complaint/processing', this.editForm)
                        .then(res => {
                            this.$emit('refreshDataList')
                            this.$message({
                                showClose: true,
                                message: '恭喜你，操作成功',
                                type: 'success',
                                onClose: () => { }
                            });
                            this.dialogVisible = false
                            this.resetForm(formName)
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        init(id) {
            this.editForm.complaintId = id
            this.dialogVisible = true;
            this.editForm.evaluationScore =5
        },

    }
}
</script>

<style scoped>

.el-rate{
    margin-top: 10px;
}</style>
